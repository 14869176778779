import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "./navbar/navbar";
import Footer from "./footer/footer";

import "./layout.scss";
import "../global.scss";

// @ts-ignore
import favicon from '../assets/favicon.ico';

const Layout = ({ children }: any) => {
  return (
    <>
      <Helmet>
        {/* {metatags.map(({ key, value }) => {
          let tag;
          if (key === 'title') {
            tag = <title key={key}>{value}</title>;
          } else if (key === 'canonical') {
            tag = <link key={key} rel={key} href={value} />;
            overrideCanonical = true;
          } else if (tagsWithName.indexOf(key) !== -1) {
            tag = <meta key={key} name={key} content={value} />;
          } else {
            tag = <meta key={key} property={key} content={value} />;
          }

          return tag;
        })} */}

        <html lang="en" />
        <link rel="icon" href={favicon} />
      </Helmet>
      <Navbar />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
