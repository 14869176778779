/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FC, ReactNode } from "react";
import { Box } from "rebass";
import Link from "../link/link";

interface Props {
  className?: string;
  id?: string;
  children?: ReactNode;
}

const Footer: FC<Props & JSX.IntrinsicElements["nav"]> = ({
  id=""
}) => {
  return (
    <Box as="footer" id={id} bg="primary" py={3}>
      <div className="container container--flex  no-margin  no-padding">
        <p className="footer--link">
          {` ${new Date().getFullYear()} EM Marketing LLC, all rights reserved.`}
        </p>
      </div>
    </Box>
  );
};

export default Footer;
