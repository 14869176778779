/** @jsx jsx */
import { jsx } from "@emotion/core";
import { FC, ReactNode } from "react";
import { Box, Link, Image } from "rebass";
// import Link from "../link/link";
import MainContainer from "../container/container";
// @ts-ignore
import Logo from "../../assets/pink-logo.png";

interface Props {
  className?: string;
  id?: string;
  children?: ReactNode;
}

const Nav: FC<Props & JSX.IntrinsicElements["nav"]> = ({
  className = "",
  id = ""
}) => {
  return (
    <Box as="nav" id={id} className={className} py={3}>
      <MainContainer alignItems="center">
        <Link href="/" variant="nav" mr={3} fontSize={5} height="50px">
          <Image src={Logo} alt="Emilie Martinez | EM Marketing LLC" height="60px" />
        </Link>
        <Link href="/projects" variant="nav" mr={3}>
          Work
        </Link>
        <Link href="/blog" variant="nav" mr={3}>
          Blog
        </Link>
        {/* <Link href="/tic-tac-toe" variant="nav" mr={3}>
          Tic tac toe
        </Link> */}
      </MainContainer>
    </Box>
  );
};

export default Nav;
